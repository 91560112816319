<template>
    <div class="input-group input-group-sm mct-space" v-show="visivel != 'false' && visivel != false" :style="tipo == 'textarea' || tipo == 'image' ? 'max-height: none!important;' : ''">

        <div class="input-group-prepend" v-if="titulo != undefined && titulo != null && titulo != '' && tipo != 'checkbox' && visivelTitulo !== false && visivelTitulo !== 'false'">
            <label :for="campo" class="col-sm-auto input-group-text bg-light" :class="[{'mct-label' : coluna == 0}]"
                :style="[coluna > 0 ? `min-width: ${coluna}px; max-width: ${coluna}px;` : '', tipo == 'textarea' || tipo == 'image' ? 'max-height: none!important;' : '']"

                >{{ titulo }}<small class="mct-obg" v-if="obrigatorio == 'true' || obrigatorio == true"> * </small></label>        
        </div>
        
        <!-- TEXT / NUMBER / DATE -->
        <input v-if="tipo in {'text':1, 'number':1, 'date':1, 'password':1, 'email':1, 'url':1} && !mascara" :type="tipo" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo" 
            class="form-control"  
            v-model="valor[campo]" 
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-sm-${tamanho}` : 'col-md-auto', {'is-invalid': errors[campo]}]"     
            :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true"
            :tabindex="tabindex"
            :style="tipo === 'number' ? 'text-align: right' : typeof alinhamentoTexto != 'undefined' ? `text-align: ${alinhamentoTexto} ` : ''"
            @input="forceUppercase($event, valor, `${campo}`, `${typeof transforme !== 'undefined' ? transforme : 'upper'}`,tipo)"
            @keyup.enter="onKeyUp()"
            @blur="onChange()"
            > 

        <!-- CURRENCY -->
        <input v-if="tipo in {'currency':1}" type="text" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo"  
            class="form-control"  
            v-model="currency" 
            v-money="money2casas"
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-sm-${tamanho}` : 'col-md-auto', {'is-invalid': errors[campo]}]"     
            :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true"
            :tabindex="tabindex"
            style="text-align: right"
            @input="unformatNumber($event, currency)"
            >
            
        <!-- HORA EXTRA -->
        <input v-if="tipo in {'horaextra':1}" type="text" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo"  
            class="form-control"  
            v-model="horaextra" 
            v-hora="hora3casas"
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'border-red' : 'border-seq', tamanho > 0 ? `col-sm-${tamanho}` : 'col-md-auto', {'is-invalid': errors[campo]}]"     
            :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true"
            v-mask="mask"
            :tabindex="tabindex"
            @input="unformatHoraExtra($event, horaextra)"
            >

        <!-- TEXT / NUMBER / DATE -->
        <input v-if="tipo in {'text':1, 'number':1, 'date':1}  && mascara" :type="tipo" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo"  
            class="form-control"  
            v-model="valor[campo]" 
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-sm-${tamanho}` : 'col-md-auto', {'is-invalid': errors[campo]}]"     
            :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true"
            v-mask="mask == null ? '' : mask"
            :tabindex="tabindex"
            @input="forceUppercase($event, valor, `${campo}`, `${typeof transforme !== 'undefined' ? transforme : 'upper'}`,tipo)"
            >  
        <i class="fa fa-2x fa-anim" v-if="tipo == 'text' && (mascara == 'cpf_cnpj' || mascara == 'cpf' || mascara == 'cnpj') && typeof valor[campo] != 'undefined' && valor[campo] != null && valor[campo] != 'null' && valor[campo] != ''" 
           :class="valCC(valor[campo]) ? 'fa-check text-success' : 'fa-times text-danger'" aria-hidden="true"></i>
        
        <!-- INTEGER -->   
        <input v-if="tipo in {'integer':1}" type="text" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo"  
            class="form-control"  
            v-model="valor[campo]" 
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-sm-${tamanho}` : 'col-md-auto', {'is-invalid': errors[campo]}]"     
            :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true"
            :tabindex="tabindex"
            oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
            >        

        <!-- TEXTAREA -->
        <textarea v-if="tipo == 'textarea'" :id="campo" :rows="linhas"
            class="form-control" 
            v-model="valor[campo]" 
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-sm-${tamanho}` : 'col-md-auto', {'is-invalid': errors[campo]}]"     
            :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true"        
            :tabindex="tabindex"
            :style="tipo == 'textarea' ? 'max-height: none!important;' : ''"
            @input="forceUppercase($event, valor, `${campo}`, `${typeof transforme !== 'undefined' ? transforme : 'upper'}`,tipo)"
            ></textarea>

        <!-- SELECT -->        
        <select v-if="tipo == 'select' && dataCombo" class="form-control" :id="campo" :placeholder="titulo"
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-sm-${tamanho}` : 'col-md-auto', {'is-invalid': errors[campo]}]"     
            v-model="valor[campo]"
            :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true"  
            :tabindex="tabindex"
            >
            <option value="" v-if="obrigatorio !== 'true' && obrigatorio !== true"></option>
            <option v-for="(registro,index) in dataCombo" :key="index" :value="origemChave ? registro[origemChave] : registro.value ? registro.value : index">{{ origemRetorno ? registro[origemRetorno] : registro.text }}</option>
        </select>

        <!-- SELECT - BUSCA BASE DADOS-->        
        <select v-if="tipo == 'select' && !dataCombo" class="form-control" :id="campo" :placeholder="titulo"
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', `col-md-${tamanho}`, {'is-invalid': errors[campo]}]"        
            v-model="valor[campo]"
            :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true"  
            :tabindex="tabindex"
            >
            <option value="" v-if="obrigatorio !== 'true' && obrigatorio !== true"></option>
            <option v-for="(registro,index) in opcoes" :key="index" :value="registro[origemChave]">
                <span v-if="origemRetorno.split(',') != -1"><span v-for="(reg,i) in origemRetorno.split(',')" :key="i"> {{ registro[reg] }} </span></span>                    
                <span v-else> {{ registro[origemRetorno] }} </span>
            </option>
        </select>        

        <!-- RADIO -->
        <div class="form-control mct-border-radio" v-if="tipo === 'radio' && dataCombo"
            :class="[ `col-md-${tamanho}`, {'is-invalid': errors[campo]}]"
            :required="obrigatorio == 'true' || obrigatorio == true"
            :tabindex="tabindex"
            >
            <div class="form-inline">
                <label class="custom-control custom-radio align-middle" v-for="(registro,index) in dataCombo" :key="index">
                    <input :id="campo" :name="campo" type="radio" class="custom-control-input" :value="registro.value" v-model="valor[campo]" 
                    :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description align-middle">{{ registro.text }}</span>
                </label>        
            </div>
        </div>

        <!-- RADIOBUT -->        
        <div class="input-group input-group-sm mct-border-radio" v-if="tipo === 'radiobut' && dataCombo"
            :class="[`col-md-${tamanho}`, {'is-invalid': errors[campo]}]"            
            :required="obrigatorio == 'true' || obrigatorio == true"
            :tabindex="tabindex"
            >
            <label class="custom-control custom-radio align-middle" v-for="(registro,index) in dataCombo" :key="index">
                <input :id="campo" :name="campo" type="radio" class="custom-control-input" :value="registro.value" v-model="valor[campo]" 
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description align-middle">{{ registro.text }}</span>
            </label>        
        </div>
        
        <!-- CHECKBOX -->
        <label v-if="tipo == 'checkbox'" class="custom-control custom-checkbox">
            <input  class="custom-control-input" type="checkbox" :name="campo" v-model="valor[campo]"
            v-bind:true-value="1"
            v-bind:false-value="0"
            :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description align-middle">{{titulo}}</span>
        </label> 
        
        <!-- IMAGE -->
        <div class="form-control col-md-12" v-if="tipo in {'image':1}" :placeholder="titulo"
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', {'is-invalid': errors[campo]}]"        
            :tabindex="tabindex"
            style="max-height: none!important;display: inline;"
            >
            <div :id="`img_${campo}`" class="mct-img">
                <img :src="img">
            </div>
            <div class="col-md-8">
                <input class="mct-btn-img" type="file" @change="onFileChange" accept="image/*">
            </div>
            <!--<div class="col-md-2">
                <button class="btn btn-success btn-block" @click.prevent.stop="upload">Upload</button>
            </div>-->
        </div>
        <!--<mct-file-upload v-if="tipo in {'image':1}"/>-->
        <!-- BUSCA MODAL -->
        <input type="text" class="form-control" :id="campo" :placeholder="titulo"
            :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-sm-${tamanho}` : 'col-md-auto', {'is-invalid': typeof errors != 'undefined' && errors[campo]}]" 
            :style="typeof alinhamentoTexto != 'undefined' ? `text-align: ${alinhamentoTexto} ` : ''"    
            v-model="valor[campo]"
            :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
            :required="obrigatorio == 'true' || obrigatorio == true" 
            v-if="tipo == 'search' || tipo == 'search-input'"
            @keyup.enter="getDescricao(valor[campo],origem,origemRetorno)"
            @blur="getDescricao(valor[campo],origem,origemRetorno)"
            :tabindex="tabindex"
        >
        
        <span class="input-group-btn" 
            v-if="tipo == 'search' || tipo == 'search-input'">
            <button class="btn btn-secondary btn-sm" type="button" id="btnPesquisa" @click="evtModal($event)"
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')" 
                tabindex="-1"
            ><i class="fa fa-search" aria-hidden="true"></i></button>
        </span>
        <span class="form-control col-md-auto"
            v-if="tipo == 'search'" :id="`dsc${campo}`" tabindex="-1" readonly>{{ retornoDescricao }}</span>


        <!-- BOTAO OPEN CADASTRO -->
        <span class="input-group-btn" 
            v-if="(tipo == 'select' || tipo == 'search') && (abrirCadastro == true || abrirCadastro == 'true')" @click="abrirPopup($props)">
            <a class="btn btn-secondary btn-sm btn-success" id="btnAbrirCadastro" href="#" alt="Abrir Cadastro."
                tabindex="-1"
            ><i class="fa fa-plus" aria-hidden="true"></i></a>
        </span>    

        <!-- BOTAO OPEN URL / EMAIL -->
        <span class="input-group-btn" 
            v-if="(tipo == 'url' || tipo == 'email') && typeof valor[campo] != 'undefined' && valor[campo] != null && valor[campo] != 'null' && valor[campo] != ''">
            <a class="btn btn-secondary btn-sm bg-info" id="btnPesquisa" :href="tipo == 'url' ? valor[campo] : 'mailto:'+valor[campo]" target="_blank" alt="Abrir endereço de Link em uma nova janela."
                tabindex="-1"
            ><i class="fa" :class="tipo == 'url' ? 'fa-edge' : 'fa-envelope'" aria-hidden="true"></i></a>
        </span>      

        <!-- OBRIGATORIO -->
        <div class="invalid-feedback" style="padding-left: 5px;" v-if="obrigatorio == 'true' || obrigatorio == true">
            * 
        </div> 

        <!-- MODAL -->
        <mct-modal v-if="tipo == 'search' || tipo == 'search-input'" :id="modalId" :titulo="this.titulo ? 'Pesquisar: ' + this.titulo : this.telaListaTitulo ? 'Pesquisar: ' + this.telaListaTitulo : '' ">
                <component :is="this.telaLista ? this.telaLista : 'MctDataViewer'" :source="`${ this.origem.replace(/_/g, '')}/lista`" :titulo="this.titulo ? this.titulo : this.telaListaTitulo ? this.telaListaTitulo : '' " showModal="true" @setDescricao="setDescricao"></component>
        </mct-modal>
        <!-- LOGRADOURO -->
    </div>   
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import http from '@/http2'

import MctModal from './MctModal.vue'
import MctDataViewer from './MctDataViewer.vue'
import MctFileUpload from './MctFileUpload.vue'

import { mapState } from 'vuex'
import helper from '../funcoes/ExisteImgUrl'
import ValidaCpfCnpj from '../funcoes/ValidaCpfCnpj.js'

import semImg from '@/assets/images/sem_foto.png'

export default {
    name: 'MctFormItens',
    props: ['coluna','linhas','tipo','campo','transforme','alinhamentoTexto','valor','valorTrue','valorFalse','titulo','subtitulo','tamanho','obrigatorio','modo','mascara',
            'origem','origemChave','origemRetorno','origemData','origemDataJson','origemDataCSV','origemLinha','origemChaveComposta', 'origemFiltro',
            'errors','tabindex','telaLista','telaListaTitulo','visivel','chave','visivelTitulo', 'abrirCadastro'],
    components: {
        MctModal,
        MctDataViewer,
        MctFileUpload,
    },
    data () {
        return {
            retornoDescricao: '',
            modalId: '1',
            opcoes: {},
            dataCombo: null,
            img: null,
            currency: {
                type: Number,
                default: null
            },
            horaextra: {
                type: Number,
                default: null
            },
            money2casas: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                /*prefix: 'R$ ',
                suffix: ' #',*/
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            hora3casas: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                /*prefix: 'R$ ',
                suffix: ' #',*/
                precision: 2,
                masked: false /* doesn't work with directive */
            }
        }
    },
    computed: {

        mask () {
            if (this.mascara == 'cpf_cnpj'){
                return ['###.###.###-##', '##.###.###/####-##'] 
            } else
            if (this.mascara == 'cpf'){
                return ['###.###.###-##'] 
            } else
            if (this.mascara == 'cnpj'){
                return ['##.###.###/####-##'] 
            } else
            if (this.mascara == 'telefone'){
                return ['(##) ####-####'] 
            } else
            if (this.mascara == 'celular'){
                return ['(##) ####-####', '(##) #####-####'] 
            } else
            if (this.mascara == 'cep'){
                return ['#####-###'] 
            } else 
            if (this.mascara == 'hora'){
                return ['##:##'] 
            } else     
            if (this.mascara == 'horaextra'){
                return ['###.##', '##.##', '#.##','#.#'] 
            } else         
            if (this.mascara == 'receita'){
                if (this.Exercicio <= 2017){
                    return ['####.##.##.##'] 
                }else{
                    return ['#.#.#.#.##.#.#.##.##.##'] 
                }
            } else            
            if (this.mascara == 'despesa'){
                if (this.Exercicio <= 2017){
                    return ['#.#.##.##.##.##'] 
                }else{
                    return ['#.#.##.##.##.##'] 
                }
            } else            
            if (typeof this.mascara != "undefined" && typeof this.mascara != "null"){
                return this.mascara 
            } else {
                return null
            }
        },
        ...mapState('Login',['Entidade','Exercicio','Competencia','EntidadeOrc','baseURL'])
    },
    methods: {
        unformatNumber($event, currency) {
            let vValor = ''
            vValor = currency.replace('.','')
            vValor = vValor.replace(',','.')
            this.valor[this.campo] = Number(vValor)
        },
        unformatHoraExtra($event, horaextra) {
            let vValor = ''
            vValor = horaextra.replace('.','')
            vValor = vValor.replace(':','.')
            this.valor[this.campo] = Number(vValor)
        },
        forceUppercase(e, o, prop, c, t) {
            if (c !== 'camel'){
                if (t === 'text' || t === 'textarea' || t === 'url' || t === 'email'){
                    const start = e.target.selectionStart
                    if ((c == 'undefined') || (c == '') || (c == 'upper')){ 
                        e.target.value = e.target.value.toUpperCase()
                        }else
                    if (c == 'lower'){ 
                        e.target.value = e.target.value.toLowerCase()
                        }            
                    this.$set(o, prop, e.target.value)
                    if (t === 'text' || t === 'textarea' || t === 'url'){
                        e.target.setSelectionRange(start, start)
                    }
                }
            }            
        },
        async getLista () {
            var url = this.origem 
            if (this.origem != '' && typeof this.origem != 'undefined' && typeof this.origem != 'null' && this.origem != null){
                if (this.origem.indexOf("/") != -1){
                    url = this.origem.replace(/_/g, '')
                }else{
                    url = this.origem.replace(/_/g, '')+'/combo'                    
                }
                if (this.origemFiltro != '' && typeof this.origemFiltro != 'undefined' && typeof this.origemFiltro != 'null' && this.origemFiltro != null){
                    url += '?'+ this.origemFiltro
                    console.log(url)
                }
                await axios.get(url)
                .then(response => {
                    this.opcoes = response.data.data
                })
                .catch(response => {
                    //console.log(response)
                })
            }
        },
        async getDescricao (busca, origem, retorno) {
            let Parms = '?'
            if ((busca != '' && typeof busca != 'undefined' && typeof busca != 'null' && busca != null) &&
                (origem != '' && typeof origem != 'undefined' && typeof origem != 'null' && origem != null)){     

                if (this.origemChaveComposta === true || this.origemChaveComposta === 'true'){
                    Parms = Parms + this.origemChave +'='+ busca
                }else{
                    Parms = '/' + busca
                }
                var url = origem.replace(/_/g, '')+'/editar'+Parms 

                if (this.origemFiltro != '' && typeof this.origemFiltro != 'undefined' && typeof this.origemFiltro != 'null' && this.origemFiltro != null){
                    url += '?'+ this.origemFiltro
                    console.log(url)
                }

                await axios.get(url)
                .then(response => {
                    this.retornoDescricao = response.data.data[retorno].toUpperCase()
                    this.setDescricao(response.data.data)
                })
                .catch(response => {
                    if (response.response.status == 404) {
                        this.retornoDescricao = 'REGISTRO NÃO LOCALIZADO'
                        this.setDataRetorno(response.response.status)
                    }
                })
            }
        }, 
        setDescricao (retorno) {
            this.valor[this.campo] = retorno[this.origemChave]             
            //$(`#dsc${this.campo}`).html(retorno[this.origemRetorno])
            if (this.tipo != 'search-input'){
                this.retornoDescricao = retorno[this.origemRetorno].toUpperCase()
            }
            this.setDataRetorno(retorno)
            if (typeof this.origemData != 'undefined' && typeof this.origemData != 'null' && this.origemData != null){
                for (var index in retorno) {
                        this.origemData[index] = retorno[index]
                    }
            }
            $('#MctModal'+this.modalId).modal('hide')
        },                   
        evtModal (ev) {
            $('#MctModal'+this.modalId).modal('show')
        },
        setDataRetorno (data) {
            this.$emit('setDataRetorno',data)
        },
        csvJSON(csv){
            var lines=csv.split("\n")
            var result = []
            var headers=lines[0].split(";")
            for(var i=1;i<lines.length;i++){
                var obj = {}
                var currentline=lines[i].split(";")
                for(var j=0;j<headers.length;j++){
                    obj[headers[j]] = currentline[j]
                }
                result.push(obj)
            }
            return result
        },        
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.createImage(files[0])
        },
        createImage(file) {
            let reader = new FileReader()
            let vm = this
            reader.onload = (e) => {
                vm.img = e.target.result
                vm.valor[vm.campo+'_img'] = e.target.result
            };
            reader.readAsDataURL(file)
        },
        async upload(){
            await axios.post('/upload',{image: this.img}).then(response => {
            
            })
        },
        valCC (valor){
            return ValidaCpfCnpj.valida_cpf_cnpj(valor)
        },
        abrirPopup(data){

            this.$store.dispatch('Popup/ABRIR_POPUP', [data, true]);

        },
        onKeyUp() {
            this.$emit('onKeyUp')    
        },
        onChange() {
            this.$emit('onChange')    
        }
    },
    created () {
        if ((this.tipo == 'select') && (!this.origemData)){
            this.getLista()  
        }else 
        if (this.tipo == 'search'){
            this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
            this.modalId = Math.floor((Math.random() * 99999999) + 1)
        }else 
        if (this.tipo == 'search-input'){
            this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
            this.modalId = Math.floor((Math.random() * 99999999) + 1)
        }
        
        if((this.origemDataJson !== null) && (typeof this.origemDataJson !== 'undefined')){
          http.get('src/components/tabsInternas/'+this.origemDataJson).then(response => {
            this.dataCombo = response.data
          });
        }else{
            this.dataCombo = this.origemData
        }

        if((this.origemDataCSV !== null) && (typeof this.origemDataCSV !== 'undefined')){
          http.get('src/components/tabsInternas/'+this.origemDataCSV).then(response => {
            var csv = response.data
            var csvJson = this.csvJSON(csv)
             this.dataCombo = csvJson
          });
        }        

        if(this.tipo == 'image'){
            let vm = this
            let caminho = this.$route.path
            caminho = caminho.substr(0, caminho.indexOf("/",1))
            caminho = this.baseURL+'public/arquivos'+caminho+'/'+this.valor[this.campo]
            this.img = helper.image(caminho, {
                success : function () {
                    //alert(this.width); 
                    },
                failure : function () {
                    vm.img = semImg;

                }/*,
                target : "#img_bnc_logo"*/
            }); 
        } 
        
        if(this.tipo == 'currency'){
            this.currency = this.valor[this.campo]
        }

        if(this.tipo == 'horaextra'){
            this.horaextra = this.valor[this.campo]
        }
    },
    mounted () {

    },
    watch: {
            'valor' () {                
                if (this.tipo == 'search'){
                    this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
                    this.modalId = Math.floor((Math.random() * 99999999) + 1)
                }else 
                if (this.tipo == 'search-input'){
                    this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
                    this.modalId = Math.floor((Math.random() * 99999999) + 1)
                }
            } ,
            'currency' () {
                return this.valor[this.campo]
            },
            'horaextra' () {
                return this.valor[this.campo]
            },
            'origemData' () {
                if((this.origemDataJson === null) || (typeof this.origemDataJson === 'undefined')){
                    this.dataCombo = this.origemData
                }
            }                       
        }          
}
</script>

<style scoped>

    .mct-btn-img{
        margin-top: 10px;
        margin-left: -5px;
    }

    /* Esconde o input */
    input[type='file'] {
        background-color: #343A40;
        color: white;
    }   

    .fa-anim {
        margin-left: 5px;
        animation: bounceIn 2s;
    }

    @keyframes bounceIn {
        0% {
            transform: scale(0.1);
            opacity: 0;
        }
        60% {
            transform: scale(1.2);
            opacity: 1;
        }
        100% {
            transform: scale(1);
        }
    }

</style>

