<template>
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-2">
                <img :src="image" class="img-responsive">
            </div>
            <div class="col-md-8">
                <input type="file" v-on:change="onFileChange" class="form-control" accept="image/*">
            </div>
            <div class="col-md-2">
                <button class="btn btn-success btn-block" @click.prevent.stop="upload">Upload</button>

				<button class="btn btn-success btn-block" @click.prevent.stop="relatorio">Teste Relatorio</button>
            </div>
        </div>

		<iframe id="frame1" src="" width="500" height="700"></iframe>
    </div>
</template>
<style scoped>
    img{
        max-height: 36px;
    }
</style>

<script>

/*
TIPOS DE MIDIA:

    Extensão de arquivo (.xls, .jpg);
    audio/* - todos arquivos de áudio;
    video/* - todos arquivos de vídeo;
    image/* - todos arquivos de imagem;
    media_type - arquivos de mídia.
*/


import Vue from 'vue'
import axios from '@/http'
import rels from 'axios'

export default {
	name: "MctFileUpload",
	data(){
		return {
			image: ''
		}
	},
	methods: {
		onFileChange(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;
			this.createImage(files[0]);
		},
		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = (e) => {
				vm.image = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		upload(){
			axios.post('/upload',{image: this.image,caminho:'teste/'}).then(response => {
			});
		},
		relatorio(){
			rels({
				/*url: axios.defaults.baseURL+'relatorios/pdf?relatorio=ListaCredores&codi=1&codf=22&mct_entidade=1',*/
				url: axios.defaults.baseURL+'aguafaturas/pdf',
				method: 'GET',
				responseType: 'blob', // arraybuffer - important
			}).then((response) => {
    			/*const file = new Blob(
      				[response.data], 
					{type: 'application/pdf'}
				);*/				
				const fileURL = URL.createObjectURL(response.data)
				document.getElementById('frame1').src = fileURL

				window.open(fileURL,'_blank')
				
				/*let routeData = this.$router.resolve({path: '/openfile', props: {link: fileURL}});
				window.open(routeData.href, '_blank');
				window.open(fileURL,'_blank')
				window.location.assign(fileURL);*/
								
				const link = document.createElement('a');
				link.href = fileURL;
				let date = new Date()
				link.setAttribute('download', date.getTime()+'.pdf');
				//link.setAttribute('href', fileURL);
				//link.setAttribute('target', '_blank');				
				document.body.appendChild(link);
				link.click();
				//window.URL.revokeObjectURL(fileURL);
							
			});			
		}
	}
}
</script>