<template>
  <b-modal :id="id !== '' ? 'modal_'+id : 'modal_empty'" :title="titulo" ok-only ok-title="Fechar" scrollable v-bind="$attrs"> 
    <slot></slot>        
  </b-modal>
</template>

<script>
export default {
    name : 'MCTModal',
    inheritAttrs: false,
    props: ['showPesquisa', 'titulo', 'id'],
    methods: {

    },
    watch: {
        showPesquisa: function (val) {

        }
    }      
}
</script>

<style>

.modal-body > .container,
.modal-body > .container > .container-fluid
 {
    padding-right: 0px;
    padding-left: 0px;
}
</style>


